.btn {
    @apply shadow text-base py-2 px-4 rounded;
}

.btn:hover {
    @apply shadow-lg;
}

.btn-large {
    @apply shadow text-base py-4 px-6 rounded;
}

.btn-large:hover {
    @apply shadow-lg;
}

.btn-primary {
    @apply shadow bg-primary text-white;
}

.btn-primary:hover {
    @apply bg-primary-dark text-white shadow-lg;
}

.btn-brand {
    @apply bg-brand text-white;
}

.btn-brand:hover {
    @apply bg-brand-dark;
}

.btn-blue {
    @apply bg-blue text-white;
}

.btn-blue:hover {
    @apply bg-blue-dark text-white;
}

.btn-green {
    @apply bg-green text-white;
}

.btn-green:hover {
    @apply bg-green-dark text-white;
}

.btn-delete {
    @apply bg-transparent border border-transparent text-red;
}

.btn-grey {
    @apply bg-grey-light text-grey-darkest;
}

.btn-grey:hover {
    @apply bg-grey;
}

.btn-disabled {
    @apply opacity-50 cursor-not-allowed;
}

.btn-success {
    @apply bg-success text-white;
}

.btn-success:hover {
    @apply bg-success-dark text-white;
}

.btn-danger {
    @apply bg-danger text-white;
}

.btn-danger:hover {
    @apply bg-danger-dark text-white;
}

.btn-info {
    @apply bg-info text-white;
}

.btn-info:hover {
    @apply bg-info-dark text-white;
}

.btn-warning {
    @apply bg-warning text-white;
}

.btn-warning:hover {
    @apply bg-warning-dark text-white;
}

.btn-blank {
    @apply bg-white border border-grey-light text-grey-darkest;
}

.btn-blank:hover {
    @apply bg-grey-lightest text-grey-darkest;
}